import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Header from '../components/header/Header';
import Footer from '../components/Footer';
import HeaderMobile from './header/HeaderMobile';

const PageContainer = () => {
  const location = useLocation();
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    // Scroll to top whenever the location changes
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const pageVariants = {
    initial: { translateY: -10, opacity: 0 },
    in: { translateY: 0, opacity: 1 },
    out: { translateY: 10, opacity: 0 }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'easeIn',
    duration: 1
  };

  return (
    <div className='relative'>
      <Header toggle={toggle} setToggle={setToggle} />

      <div className='relative z-40 bg-white overflow-hidden'>
        <motion.div
          key={location.pathname}
          initial='initial'
          animate='in'
          exit='out'
          variants={pageVariants}
          transition={pageTransition}
        >
          <Outlet />
        </motion.div>
             <HeaderMobile toggle={toggle} />
      </div>
      <Footer />
    </div>
  );
};

export default PageContainer;
