import React from 'react';
import { useLocation } from 'react-router-dom';
import UrunlerMenuComponent from '../components/urunler/UrunlerMenuComponent';
import UrunlerContentComponent from '../components/urunler/UrunlerContentComponent';
import { urunlerData } from '../data/urunler-data';

const Urunler = () => {
  const location = useLocation();
  const fullPath = location.pathname;
  const pathParts = fullPath.split('/').filter(part => part !== "");
  const firstPath = pathParts[1] || '';
  const secondPath = pathParts[2] || '';

  const filteredFirstPath = urunlerData.filter((e) => firstPath === e.link);
  let filteredSecondPath = filteredFirstPath[0]?.data.filter((e) => secondPath === e.link);

  // If filteredSecondPath is empty, use all items from filteredFirstPath
  if (!filteredSecondPath || filteredSecondPath.length === 0) {
    filteredSecondPath = filteredFirstPath[0]?.data || [];
  }
  

  return (

    <div className='grid grid-cols-10 my-10 md:my-20 w-full'>
 
      <div className='col-start-2 col-span-8 grid grid-cols-5 w-full'>
      
      

        <UrunlerMenuComponent filteredFirstPath={filteredFirstPath} firstPath={firstPath} secondPath={secondPath}/>
        <UrunlerContentComponent filteredSecondPath={filteredSecondPath} />
      </div>
    </div>

  );
};

export default Urunler;
