import React from 'react'
import { motion } from 'framer-motion';
const Kurumsal = () => {
    return (
        <div>
            <motion.img   src='/images/kurumsal/kurumsl_banner.jpg' className='h-[40vh] w-full object-cover bg-slate-600' />
            {/* <motion.img   src='' className='h-[40vh] w-full object-cover bg-slate-600' /> */}
            <div className='grid grid-cols-10'>
                <div className='col-start-2 col-span-8'>

                    <div className='w-full flex flex-col py-20 space-y-10'>
                        <motion.div
                        initial={{ opacity: 0 }} 
                        whileInView={{ opacity: 1 }} 
                        exit={{ opacity: 0 }} // Reverse the animation on exit
                        transition={{ 
                          duration: 0.5, 
                          delay: 0.5 
                        }} 
                        className='flex flex-col'>
                            <h6 className='text-22 font-bold text-liderBlue mb-5'>HAKKIMIZDA</h6>
                            <div className='flex flex-col text-18 space-y-5'>
                                <p>Lider Makina-Mühendislik, kuruluşundan itibaren sektördeki yenilikçi yaklaşımlarıyla öncü bir konumda yer almaktadır. Deneyimli ve dinamik teknik ekibimiz, geniş stok kapasitemiz ve müşteri memnuniyetini en üst düzeyde tutma hedefimizle sektörde örnek bir yapı oluşturduk. Müşteri odaklı çalışma anlayışımız ve yüksek kaliteli ürün sunma misyonumuz, elde ettiğimiz başarıyı artan müşteri memnuniyeti ve genişleyen pazar payımızla pekiştirmektedir.</p>
                                <p>Lider Makina, derin teknik bilgisi ve müşteri odaklı yaklaşımı sayesinde makine ve ekipman alanında değer yaratan bir çözüm ortağı olmuştur. Denizli merkezli olarak tüm Türkiye’ye hizmet sunan markamız, ülkenin en büyük ve başarılı şirketleri arasında yer alma hedefimize sıkı sıkıya bağlıdır.
                                </p>
                            </div>
                        </motion.div>
                        <motion.div          initial={{ opacity: 0 }} 
                        whileInView={{ opacity: 1 }} 
                        exit={{ opacity: 0 }} // Reverse the animation on exit
                        transition={{ 
                          duration: 0.5, 
                          delay: 1 
                        }}  className='flex flex-col  '>
                            <h6 className='text-22 font-bold  text-liderBlue mb-5'>MİSYON</h6>
                            <div className='flex flex-col text-18 space-y-5'>
                                <p>Müşterilerimize en yüksek kalite standartlarında ürün ve hizmet sunarak iş süreçlerinin kesintisiz ve verimli bir şekilde devamını sağlamak. Yenilikçi üretim teknikleri ve sürdürülebilir malzeme kullanımı ile sektörde güvenilir bir çözüm ortağı olarak konumlanmak, müşteri memnuniyetini her zaman ön planda tutarak sektörün gelişimine katkıda bulunmak.</p>
                                {/* <p>Müşterilerimize daha iyi hizmet verebilmek için gerekli bilgi birikimine sahip olan uzman personeli ve çağın gereksinimlerini karşılayan son model ve son sistem makineleriyle donanımını güçlendirmekte, yeni teknolojileri sürekli takip ederek, gerekli eğitimlerle bilgilerini güçlendirerek, kendimizi yenilemekteyiz.</p> */}
                            </div>
                        </motion.div>
                        <motion.div          initial={{ opacity: 0 }} 
                        whileInView={{ opacity: 1 }} 
                        exit={{ opacity: 0 }} // Reverse the animation on exit
                        transition={{ 
                          duration: 0.5, 
                          delay: 1.5
                        }}  className='flex flex-col  '>
                            <h6 className='text-22 font-bold  text-liderBlue mb-5'>VİZYON</h6>
                            <div className='flex flex-col text-18 space-y-5'>
                                <p>Güvenilir ve yenilikçi bir marka olarak sektörde öncü çözümler sunmak ve sürdürülebilir büyümeyi sağlamak. Teknolojik yenilikler ve Ar-Ge yatırımları ile sektörde kalite standartlarını belirleyen bir marka haline gelmek ve müşteri odaklı yaklaşımımızla iş ortaklarımızın vazgeçilmez bir parçası olmak.
                                </p>
                                {/* <p>Müşterilerimize daha iyi hizmet verebilmek için gerekli bilgi birikimine sahip olan uzman personeli ve çağın gereksinimlerini karşılayan son model ve son sistem makineleriyle donanımını güçlendirmekte, yeni teknolojileri sürekli takip ederek, gerekli eğitimlerle bilgilerini güçlendirerek, kendimizi yenilemekteyiz.</p> */}
                            </div>
                        </motion.div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Kurumsal