import React from 'react'
import { motion } from 'framer-motion'

const Section3 = () => {
  return (
    <section className="grid grid-cols-10 h-[50vh] w-full mb-32">
      <motion.div 
        className="col-start-2 col-span-8 relative h-full w-full"
        initial={{ opacity: 0, scale: 1.1 }}
        whileInView={{ opacity: 1, scale: 1 }}
  
        transition={{ duration: 0.8, ease: 'easeOut' }}
      >
        <motion.img
          src='/images/anasayfa/banner/banner.jpg'
          className="w-full h-full object-cover absolute top-0 left-0"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
         
          transition={{ duration: 0.8, ease: 'easeOut' }}
        />
        <motion.div
          className="w-full h-full flex justify-end items-end flex-col pb-[7%] px-[8%] relative z-10"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
         
          transition={{ duration: 0.8, ease: 'easeOut' }}
        >
          <h5 className="text-end responsive-text-55-3xl text-white font-bold mb-4 w-full md:w-1/2">
          Endüstriyel çözümlerimizle üretiminize güç katıyoruz.

          </h5>
          {/* <p className="text-18 text-white">High-tech equipment and elaborate control</p> */}
        </motion.div>
      </motion.div>
    </section>
  )
}

export default Section3
