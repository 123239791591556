export const referencesData = [
    {
        image: '/images/references/akca.png',
        name: ''
    },
    {
        image: '/images/references/Akkusoglu.png',
        name: ''
    },
    {
        image: '/images/references/almes.png',
        name: ''
    },
    {
        image: '/images/references/ark.png',
        name: ''
    },
    {
        image: '/images/references/arnes.png',
        name: ''
    },
    {
        image: '/images/references/Bati-Ege.png',
        name: ''
    },
    {
        image: '/images/references/beysu.png',
        name: ''
    },
    {
        image: '/images/references/birdal-marble.png',
        name: ''
    },
    {
        image: '/images/references/canogullari.png',
        name: ''
    },
    {
        image: '/images/references/Cobanlar-Group.png',
        name: ''
    },
    {
        image: '/images/references/Denizli-Cimento.png',
        name: ''
    },
    {
        image: '/images/references/dim.png',
        name: ''
    },
    {
        image: '/images/references/Doamar.png',
        name: ''
    },
    {
        image: '/images/references/hayrettin-unal.png',
        name: ''
    },

        {
        image: '/images/references/hestas.png',
        name: ''
    },
    {
        image: '/images/references/imsa-marble.png',
        name: ''
    },
    {
        image: '/images/references/ionic-stone.png',
        name: ''
    },
    {
        image: '/images/references/makmer.png',
        name: ''
    },
    {
        image: '/images/references/makro.png',
        name: ''
    },

    {
        image: '/images/references/mesamer.png',
        name: ''
    },
    {
        image: '/images/references/MODULMER.png',
        name: ''
    },
    {
        image: '/images/references/ozdoganlar.png',
        name: ''
    },
    {
        image: '/images/references/remsu.png',
        name: ''
    },
    {
        image: '/images/references/stoneworld.png',
        name: ''
    },
    {
        image: '/images/references/tenoka.png',
        name: ''
    },
    {
        image: '/images/references/Travertine-Bros.png',
        name: ''
    },
    {
        image: '/images/references/Akdeniz.png',
        name: ''
    },
    {
        image: '/images/references/alimoglu.png',
        name: ''
    },
    {
        image: '/images/references/Artmersan.png',
        name: ''
    },
    {
        image: '/images/references/Aselmak.png',
        name: ''
    },
    {
        image: '/images/references/ASKON.png',
        name: ''
    },
    {
        image: '/images/references/ba-marmor.png',
        name: ''
    },
    {
        image: '/images/references/Bazalto.png',
        name: ''
    },
    {
        image: '/images/references/Cetin.png',
        name: ''
    },
    {
        image: '/images/references/Demkaya.png',
        name: ''
    },
    {
        image: '/images/references/demmer.png',
        name: ''
    },
    {
        image: '/images/references/Denmak.png',
        name: ''
    },
    {
        image: '/images/references/DENTAS.png',
        name: ''
    },
    {
        image: '/images/references/DN-Marble.png',
        name: ''
    },
    {
        image: '/images/references/Ece-Marble.png',
        name: ''
    },
    {
        image: '/images/references/Faber.png',
        name: ''
    },
    {
        image: '/images/references/Fernas.png',
        name: ''
    },
    {
        image: '/images/references/GACAOGLU.png',
        name: ''
    },
    {
        image: '/images/references/Gulmer.png',
        name: ''
    },

    {
        image: '/images/references/Haz-Marble.png',
        name: ''
    },
    {
        image: '/images/references/Hbb-marble.png',
        name: ''
    },
    {
        image: '/images/references/Karmak.png',
        name: ''
    },
    {
        image: '/images/references/Kaynak.png',
        name: ''
    },
    {
        image: '/images/references/Kaynak-Group.png',
        name: ''
    },
    {
        image: '/images/references/Komurcuoglu.png',
        name: ''
    },
    {
        image: '/images/references/Kur-Marble.png',
        name: ''
    },
    {
        image: '/images/references/Loadex.png',
        name: ''
    },
    {
        image: '/images/references/Metamar.png',
        name: ''
    },
    {
        image: '/images/references/Moyak.png',
        name: ''
    },
    {
        image: '/images/references/MRD.png',
        name: ''
    },
    {
        image: '/images/references/Ozdenler-Grup.png',
        name: ''
    },
    {
        image: '/images/references/Pinar-Marble.png',
        name: ''
    },
    {
        image: '/images/references/Sorkun-Marble.png',
        name: ''
    },
    {
        image: '/images/references/Stone-Terroir.png',
        name: ''
    },
    {
        image: '/images/references/Tekmar.png',
        name: ''
    },
    {
        image: '/images/references/Tikiroglu.png',
        name: ''
    },
    {
        image: '/images/references/Tuna-Marble.png',
        name: ''
    },
    {
        image: '/images/references/Zafer-Maden.png',
        name: ''
    },


    


];
