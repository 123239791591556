import { useLocation } from "react-router-dom";

import { useEffect } from "react";

import { useLenis } from '@studio-freight/react-lenis';
import HomeSlider from "../components/HomeSlider";
import Section2 from "../components/home/Section2";
import Section3 from "../components/home/Section3";
import Section4 from "../components/home/Section4";



function Anasayfa() {

  const lenis = useLenis();

  const handleDownArrowClick = () => {
    if (lenis) {
      const viewportHeight = window.innerHeight;
      lenis.scrollTo(viewportHeight + 30);
    }
  };
  


  return (

    <div className="">

      <div className="bg-white relative z-20">
        <div className="grid grid-cols-10 ">
          <div className="col-start-2 col-span-8  ">
            <HomeSlider />

            <div className="h-[20vh] w-full flex justify-center items-center">
              <img   onClick={handleDownArrowClick}   src="/images/icons/down.svg" className="h-[20%] w-full cursor-pointer" />
            </div>
          </div>
        </div>



        <Section2 />

        <Section3 />
        {/* <Section4 /> */}

      </div>




    </div>

  );
}

export default Anasayfa;
