import React from "react";
import { motion } from "framer-motion";

const Blog = () => {
  const blogData = [
    {
      title: "Ekskavatör Grubu Hizmetleri",
      img:'/images/blog/blog_excvator.jpg',
      data: [
        {
          title: "Ekskavatör Kovaları",
          data: [
            {
              p: "Dayanıklı ve verimli ekskavatör kovalarımız, her türlü kazı ve taşıma işlerinde üstün performans sunar. Bu makine parçaları, işlerinizi kolaylaştırmak için tasarlanmıştır.",
            },
          ],
        },
        {
          title: "Ekskavatör Hızlı Birleştiriciler",
          data: [
            {
              p: "Kolayca monte edilen hızlı birleştiricilerimiz, işlerinizi hızlandırarak verimliliği artırır. Bu iş makinası yedek parçaları, operasyonel etkinliğinizi artırmak için idealdir.",
            },
          ],
        },
        {
          title: "Ekskavatör Riperleri",
          data: [
            {
              p: "Zorlu zemin koşullarında üstün performans gösteren riperlerimiz, kazı ve kırma işlemlerinde etkilidir. Ekskavatörler için sunduğumuz bu makina yedek parçaları, işinizin kalitesini artırır.",
            },
          ],
        },
        {
          title: "Ekskavatör Kırıcı Braketleri",
          data: [
            {
              p: "Kırıcı braketlerimiz, ekskavatörünüzü daha işlevsel hale getirerek çeşitli kırma işlemlerini kolayca yapmanızı sağlar. Bu iş makinası parçaları, zorlu görevler için mükemmeldir.",
            },
          ],
        },
        {
          title: "Ekskavatör Pamtörleri",
          data: [
            {
              p: "Pamtörlerimiz, ekskavatörünüzün kazı ve taşıma kapasitesini artıran önemli aksesuarlardır. Bu makina yedek parçaları, iş makinelerinizin verimliliğini artırır.",
            },
          ],
        },
        {
          title: "Ekskavatör Ana Boom, Çekirge ve Uç Boom",
          data: [
            {
              p: "Ekskavatörünüzün manevra kabiliyetini artıran ana boom, çekirge ve uç boom ürünlerimizle çalışmalarınızı daha verimli hale getirin. Bu iş makinası ekipmanları, daha geniş alanlarda çalışmanızı sağlar.",
            },
          ],
        },
      ],
    },
    {
      title: "Yükleyici Grubu Hizmetleri",
      img:'/images/blog/yukleyici_grubu.jpg',
      data: [
        {
          title: "Yükleyici Kovaları ",
          data: [
            {
              p: "Her türlü yük taşıma işlerinde dayanıklı ve verimli yükleyici kovalarımızı kullanabilirsiniz. Bu makina yedek parçaları, iş makinelerinizin kapasitesini artırır.",
            },
          ],
        },
        {
          title: "Yükleyici Hızlı Birleştiriciler",
          data: [
            {
              p: "Hızlı birleştiricilerimizle yükleyici ataşman değişimlerinizi hızlı ve kolay bir şekilde gerçekleştirin. Bu iş makinası yedek parçaları, iş süreçlerinizi hızlandırır.",
            },
          ],
        },
        {
          title: "Yükleyici Riperleri",
          data: [
            {
              p: "Zorlu zemin koşullarında kullanılmak üzere tasarlanmış yükleyici riperlerimizle performansınızı artırın. Bu makina parçaları, iş makinelerinizin etkinliğini artırır.",
            },
          ],
        },
        {
          title: "Ekskavatör Kırıcı Braketleri",
          data: [
            {
              p: "Kırıcı braketlerimiz, ekskavatörünüzü daha işlevsel hale getirerek çeşitli kırma işlemlerini kolayca yapmanızı sağlar. Bu iş makinası parçaları, zorlu görevler için mükemmeldir.",
            },
          ],
        },
        {
          title: "Yükleyici Kırıcı Braketleri",
          data: [
            {
              p: "Kırıcı braketlerimiz, yükleyicilerinize ek işlevsellik kazandırır ve çeşitli kırma işlemlerini kolaylaştırır. Bu iş makinası yedek parçaları, kırma işlemlerinde yüksek performans sunar.",
            },
          ],
        },
        {
          title: "Yükleyici Pamtörleri",
          data: [
            {
              p: "Pamtörlerimiz, yükleyicilerinizin verimliliğini ve kapasitesini artıran önemli aksesuarlardır. Bu makina yedek parçaları, iş makinelerinizin performansını üst düzeye çıkarır.",
            },
          ],
        },
        {
          title: "Yükleyici Ana Boom, Çekirge ve Uç Boom",
          data: [
            {
              p: "Yükleyici ekipmanlarınızın manevra kabiliyetini ve erişim kapasitesini artıran bu ürünlerle çalışmalarınızı daha verimli hale getirin. Bu iş makinası ekipmanları, daha büyük işler yapmanızı sağlar.",
            },
          ],
        },
      ],
    },
    {
      title: "Özel Üretim ve İmalat Hizmetleri",
      img:'/images/blog/blog_ozel_uretim.jpg',
      data: [
        {
          title: "Pim, Burç ve Seyyar - Sabit Borverk ",
          data: [
            {
              p: "Özel üretim ve imalat hizmetlerimiz, pim, burç ve borverk gibi yüksek hassasiyet gerektiren parçaların üretimini kapsamaktadır. Bu makina yedek parçaları, özel ihtiyaçlarınıza göre üretilir.",
            },
          ],
        },
        {
          title: "Hassas Üretim",
          data: [
            {
              p: "Yüksek kaliteli ve hassas üretim standartlarımızla, ihtiyaçlarınıza özel çözümler sunuyoruz. Bu iş makinası yedek parçaları, yüksek performans ve uzun ömürlü kullanım sağlar.",
            },
          ],
        },
        {
          title: "Esnek Üretim Kapasitesi",
          data: [
            {
              p: "Farklı sektörlerin ihtiyaçlarına yönelik esnek üretim kapasitemizle, her türlü talebe hızlı yanıt veriyoruz. Bu makina parçaları, çeşitli endüstrilerde kullanılmak üzere üretilir.",
            },
          ],
        },
        {
          title: "Müşteri Odaklı Üretim",
          data: [
            {
              p: "Müşteri memnuniyetini en üst düzeyde tutmak için özel taleplerinize göre üretim yapmaktayız. Bu iş makinası yedek parçaları, müşterilerimizin beklentilerini karşılamak için özel olarak üretilir.",
            },
          ],
        },

        
      ],
    },
  ];

  return (
    <div className="grid grid-cols-10 mt-20">
      <div className="col-start-2 col-span-8 w-full">
        {blogData.map((item, index) => {
          const isEven = index % 2 === 0;

          return (
            <motion.div
              className="grid grid-cols-8 mb-20"
              key={index}
              initial={{ opacity: 0, x: isEven ? -50 : 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: isEven ? 50 : -50 }}
              transition={{ duration: 0.5 }}
            >
              <div
                className={`col-span-8 lg:col-span-5 lg:pr-20 pr-0 order-2 ${isEven ? "lg:pl-0 lg:order-1" : "lg:pl-20 lg:order-2"}`}
              >
                <h4 className="text-[1.2rem] text-liderBlue font-bold mb-10">
                  {item.title}
                </h4>
                {item.data.map((subItem, subIndex) => (
                  <div className="mb-10" key={subIndex}>
                    <h5 className="text-22 font-medium text-liderBlue">
                      {subItem.title}
                    </h5>
                    {subItem.data.map((paraItem, paraIndex) => (
                      <p className="text-18" key={paraIndex}>
                        {paraItem.p}
                      </p>
                    ))}
                  </div>
                ))}
              </div>
              <motion.div
                className={`col-span-8 lg:col-span-3 order-1 ${
                  isEven ? "lg:order-2" : "lg:order-1"
                } bg-lime-900 mb-10 lg:mb-0`}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.5 }}
              >
                <img
                  className="h-full w-full object-cover"
                  src={item.img}
                  alt={`${item.title} Image`}
                />
              </motion.div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default Blog;
