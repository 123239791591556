import React from 'react'
import { Link } from 'react-router-dom'
import { useLenis } from '@studio-freight/react-lenis';

const footerData = [
    {
        title: 'ANASAYFA',
        link: '/',
    },
    {
        title: 'KURUMSAL',
        link: '/kurumsal',
        data: [
            {
                title: 'Hakkımızda',
                link: '/kurumsal'
            },
            {
                title: 'Vizyon',
                link: '/kurumsal'
            },
            {
                title: 'Misyon',
                link: '/kurumsal'
            },
        ]
    },
    {
        title: 'ÜRÜNLER',
        link: '/urunler',
        data: [
            {
                title: 'Ekskavatör Ürünleri',
                link: '/urunler/ekskavator-urunleri'
            },
            {
                title: 'Yükleyici Ürünleri',
                link: '/urunler/yukleyici-urunleri'
            },
            {
                title: 'Beko Kazıcı Ürünleri',
                link: '/urunler/beko-kazici-yukleyici'
            },
            {
                title: 'Özel Üretim',
                link: '/urunler/ozel-urunler'
            },
        ]
    },
    {
        title: 'HİZMETLERİMİZ',
        link: '/hizmetlerimiz',
        data: [
            {
                title: 'Yedek Parça',
                link: '/hizmetlerimiz'
            },
            {
                title: 'Yerinde Servis - Tamirat',
                link: '/hizmetlerimiz'
            },
            {
                title: 'Ekipman Satış',
                link: '/hizmetlerimiz'
            },
        ]
    },
    {
        title: 'İLETİŞİM',
        link: '/iletisim',
        data: [
            {
                title: 'Adres',
                link: '/iletisim'
            },
            {
                title: 'Telefon',
                link: '/iletisim'
            },
            {
                title: 'Mail',
                link: '/iletisim'
            },
            {
                title: 'Google map',
                link: 'https://maps.app.goo.gl/BMEHMaLQG52uy8sM8'
            },
        ]
    },
]





const Footer = () => {
    const lenis = useLenis();

    const handleScrollToTop = () => {
        if (lenis) {
            lenis.scrollTo(0);
        }
    };
    
  return (
    <div className='flex flex-col  relative sm:sticky bottom-0 font-poppins-o border-t border-t-white z-0'>
    <div className="grid grid-cols-10  bg-liderBlue2 ">
        <div className="col-start-2 col-span-8 h-full w-full  py-24 flex">
            <div className='flex flex-col w-full'>
                <div className=' w-full  flex justify-between mb-20'>
                    <div>
                        <img src='/images/logo/white-logo.svg' />
                    </div>


                </div>
                <div className=' w-full  grid grid-cols-12'>
                    <div className='  col-span-12 md:col-span-3 mb-10 md:mb-0   text-16 text-[rgba(255,255,255,0.6)] font-light'>
                        <div className='!font-medium mb-5'>İletişim</div>
                        {/* <a href='tel:+905441216000' target='_blank' className='block'> +90 544 121 60 00</a> */}
                        <a href='tel:+905441216000'  target='_blank' className='block mb-2 hover:text-ulusOrange transition-all duration-500'>+90 544 121 60 00</a>
                        <a href='https://maps.app.goo.gl/BMEHMaLQG52uy8sM8' target='_blank' className='block mb-16 hover:text-ulusOrange transition-all duration-500'>Kaklık Mahallesi
                            <br />
                            Kaklık Sanayi Sitesi No:17
                            <br />
                            Honaz / DENİZLİ</a>
                        {/* <a href='mailto:' target='_blank' className='block hover:text-ulusOrange transition-all duration-500'>lidermakinamuhendislik.com</a> */}

                    </div>
                    <div className=' col-span-12 md:col-span-9   grid  grid-cols-1 sm:grid-cols-5 text-16 gap-5'>
                        {
                            footerData.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <Link to={item.link} className='mb-5 block font-medium hover:text-ulusOrange transition-all duration-500 text-[rgba(255,255,255,0.6)]'>{item.title}</Link>
                                        <div className='flex flex-col text-[rgba(255,255,255,0.6)]'>
                                            {
                                                item.data && item.data.map((item, index) => {
                                                    return (
                                                        <Link to={item.link} className='block hover:text-ulusOrange transition-all duration-500'>{item.title}</Link>
                                                    )
                                                })
                                            }

                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className='flex justify-start space-x-2  w-full mt-10 md:hidden'>
                        <a href='https://www.facebook.com/profile.php?id=61561246541932' target='_blank'><img className='h-4 w-4' src='/images/iletisim/white3.svg' /></a>
                    <a href='https://www.instagram.com/lidermakinamuhendislik/' target='_blank'><img className='h-4 w-4' src='/images/iletisim/white1.svg' /></a>
                    <a href='https://www.linkedin.com/company/103345853/admin/feed/posts/' target='_blank'><img className='h-4 w-4' src='/images/iletisim/white2.svg' /></a>
                   
                        </div>
                    </div>

                </div>
            </div>
            <div className='w-16 h-full   flex-col justify-between items-end hidden md:flex'>
                <div className='flex justify-center space-x-2'>
                <a href='https://www.facebook.com/profile.php?id=61561246541932' target='_blank'><img className='h-4 w-4' src='/images/iletisim/white3.svg' /></a>
                    <a href='https://www.instagram.com/lidermakinamuhendislik/' target='_blank'><img className='h-4 w-4' src='/images/iletisim/white1.svg' /></a>
                    <a href='https://www.linkedin.com/company/103345853/admin/feed/posts/' target='_blank'><img className='h-4 w-4' src='images/iletisim/white2.svg' /></a>
                   
                </div>
                <button onClick={handleScrollToTop} className="w-5">
                    <img src="/images/icons/down-arrow-white.svg" alt="Scroll to Top" />
                </button>


            </div>

        </div>

    </div>
    <div className="grid grid-cols-8  bg-white    py-5">
        <div className="col-start-2 col-end-8 h-full w-full   grid grid-cols-3 text-12 text-[rgba(0,0,0,0.5)]">
            <div>©Tüm hakları saklıdır Lider Makina-Mühendislik</div>
            <div></div>
            <a href='https://macart.com.tr/' target='_blank' className='flex justify-end'>MAC ART</a>
        </div> </div>
</div>
  )
}

export default Footer