import React from 'react'
import { Link } from 'react-router-dom'

const UrunlerMenuComponent = ({ filteredFirstPath, firstPath, secondPath }) => {

  if (!filteredFirstPath) {
    return (
      <div className='h-48 w-full bg-red-700'></div>
    )
  }

  return (
    <div className='col-span-5 md:col-span-1 text-12 mb-10 pr-5'>
      <div className='text-22 font-bold mb-5 text-liderBlue'>{filteredFirstPath[0]?.name}</div>
      <div>
      <Link to={`${firstPath}`} className={`${secondPath === "" ? 'text-liderBlue' : ''} text-16 `}>Tümü</Link>

      {filteredFirstPath[0]?.data?.map((item, index) => {
        const isActive = item.link === secondPath;
        return (
          <Link
            key={index}
            to={`${firstPath}/${item.link}`}
            className={`block text-16 ${isActive ? 'text-liderBlue font-semibold' : ''}`}
          >
            {item.name}
          </Link>
        )
      })}
      </div>
    </div>
  )
}

export default UrunlerMenuComponent
