import React from 'react'
import { motion } from 'framer-motion';

const hizmetlerimizData = [
    {
        title:'Yedek Parça',
        content:'Lider Makina Mühendislik, modern ve yenilikçi üretim tekniklerini kullanarak iş makinesi ekipmanları ve yedek parçaları alanında yüksek kalite standartlarını gözeten çözümler sunar. Müşteri memnuniyetini ön planda tutan firmamız, dayanıklı ve güvenilir ürünleriyle sektörde fark yaratmaktadır.',
        image:'/images/hizmetlerimiz/1.jpg'
    },
    {
        title:'Ekipman Satış',
        content:'Lider Makina Mühendislik, iş makinesi ekipman satışlarında kaliteyi ön planda tutar. Ürünlerimiz, uluslararası standartlara uygunluk ve dayanıklılığıyla öne çıkar. Müşteri memnuniyetini en üst düzeyde tutarak, güvenilir ve uzun ömürlü ekipmanlar sunarız.',
        image:'/images/hizmetlerimiz/2.jpg'
    },
    {
        title:'Yerinde Servis -Tamirat',
        content:'Lider Makina Mühendislik, iş makinelerinin yerinde servis ve tamirat hizmetleriyle müşterilerine zaman ve maliyet avantajı sağlar. Uzman ekibimiz, hızlı ve etkili müdahalelerle makinelerinizin kesintisiz çalışmasını sağlar, böylece operasyonlarınızda verimlilik ve süreklilik elde edersiniz.',
        image:'/images/hizmetlerimiz/3.jpg'
    },
]

const Hizmetlerimiz = () => {
    return (
        <div className='grid grid-cols-10 md:mt-20 '>
   {hizmetlerimizData.map((item, index) => {
        // Generate a random delay between 0 and 1 seconds (1000ms)
        const randomDelay = Math.random();

        return (
          <motion.div 
            key={index}
            className='col-start-2 col-span-8 grid grid-cols-3 mb-20 md:space-x-10'
            initial={{ opacity: 0, y: -50 }} // Initial state (invisible and slightly below)
            whileInView={{ opacity: 1, y: 0 }} // Animate to visible and in place
            exit={{ opacity: 0, y: -50 }} // Exit animation (fading out and moving down)
            transition={{ 
              duration: 0.6, 
              delay: index*0.1
            }}
          >
            <div className='col-span-3 md:col-span-1 md:order-1 order-2'>
              <h5 className='text-liderBlue responsive-text-55-2xl mb-5'>
                {item.title}
              </h5>
              <p className='text-18 text-customGray'>{item.content}</p>
            </div>
            <motion.div 
              className='h-[30vh] col-span-3 md:col-span-2 md:order-2 order-1 mb-5 md:mb-0'
              initial={{ scale: 0.9, opacity: 0 }} // Initial state for the image container
              whileInView={{ scale: 1, opacity: 1 }} // Animate to full size and opacity
              exit={{ scale: 0.9, opacity: 0 }} // Exit animation (shrinking and fading out)
              transition={{ 
                duration: 1, 
                delay: index*0.3
              }}
            >
              <img src={item.image} className='w-full h-full object-cover' alt={item.title} />
            </motion.div>
          </motion.div>
        );
      })}

        </div>
    )
}

export default Hizmetlerimiz