import React from 'react';
import { motion } from 'framer-motion';
import { referencesData } from '../data/references-data';

const References = () => {
  return (
    <div className='grid grid-cols-10 py-20 min-h-screen'>
      <div className='grid col-start-2 col-span-8 lg:col-start-3 lg:col-span-6 w-full'>
        <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-2 w-full'>
          {referencesData.map((item, index) => {
            // Generate a random delay between 0 and 1 seconds (1000ms)
            const randomDelay = Math.random();

            return (
              <motion.img 
                key={index} 
                src={item.image} 
                className='w-full'
                initial={{ opacity: 0 }} 
                whileInView={{ opacity: 1 }} 
                exit={{ opacity: 0 }} // Reverse the animation on exit
                transition={{ 
                  duration: 0.5, 
                  delay: randomDelay * 1.2 
                }} 
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default References;
