import React from 'react';
import { motion } from 'framer-motion';

const Iletisim = () => {
  return (
    <motion.div 
      className='grid grid-cols-10 py-20'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <motion.div 
        className='grid col-start-2 col-span-8 lg:col-start-2 lg:col-span-8'
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.3 }}
      >
        <div className='grid grid-cols-10'>
          <motion.div 
            className='col-span-10 md:col-span-4 lg:col-span-2 text-liderBlue3 space-y-10 mb-20 md:mb-0'
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.5 }}
          >
            <h6 className='text-32 font-bold text-black'>İletişim Detayları</h6>

            <div className='flex space-x-5'>
              <img src='/images/iletisim/3.svg' className='h-9 w-9' />
              <div className='text-16'>
                {/* <a href='tel:+905352035699' target='_blank' className='block'>
                +90 535 203 56 99</a> */}
                <a href='tel:+905441216000' target='_blank' className='block'> +90 544 121 60 00</a>
              </div>
            </div>

            <div className='flex space-x-5'>
              <img src='/images/iletisim/1.svg' className='h-9 w-9' />
              <div className='text-16'>
                <a href='mailto:lidermakinamuhendislik.com' target='_blank' className='block'>lidermakinamuhendislik.com</a>
                <div className='flex space-x-2'>
                  <a href='https://www.facebook.com/profile.php?id=61561246541932' target='_blank'><img src='/images/iletisim/facebook.svg' className='h-4' /></a>
                  <a href='https://www.instagram.com/lidermakinamuhendislik/' target='_blank'><img src='/images/iletisim/instagram.svg' className='h-4' /></a>
                  <a href='https://www.linkedin.com/feed/' target='_blank'><img src='/images/iletisim/linkedin.svg' className='h-4' /></a>
                </div>
              </div>
            </div>

            <div className='flex space-x-5'>
              <img src='/images/iletisim/2.svg' className='h-9 w-9' />
              <div className='text-16'>
                <a href='https://maps.app.goo.gl/CY84ZGPUJ7cSp8oLA' target='_blank' className='block'>
                  Kaklık Mahallesi
                  <br />
                  Kaklık Sanayi Sitesi No:17
                  <br />
                  Honaz / DENİZLİ
                </a>
              </div>
            </div>
          </motion.div>

          <motion.div 
            className='col-span-10 md:col-start-6 md:col-span-5 lg:col-start-4 lg:col-span-7 bg-blue-500'
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.7 }}
          >
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4402.993625671435!2d29.401829000000003!3d37.826497!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14c71d9122cfbf65%3A0xea484d9f9e658534!2zTMSwREVSIE1BS8SwTkEgTcOcSEVORMSwU0zEsEsgU0FOQVnEsCBWRSBUxLBDQVJFVCBMxLBNxLBURUQgxZ7EsFJLRVTEsA!5e1!3m2!1str!2sus!4v1722844886552!5m2!1str!2sus" 
              width="100%" 
              height="100%" 
              allowFullScreen="" 
              loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade"
            />
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default Iletisim;