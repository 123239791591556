import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import { motion } from "framer-motion";

const DATA = [
  {
    id: 1,
    image: "/images/anasayfa/slider/lider1.jpg",
    title: "Teknoloji ve Tecrübenin Gücüyle Yanınızdayız",
    // description: "Sektördeki En Ileri Çözümleri Sunuyor. İnovasyon Ve Kaliteyi Bir Araya Getirerek Müşterilerimize Üstün Performans Ve Dayanıklılık Vaat Ediyoruz."
  },
  {
    id: 2,
    image: "/images/anasayfa/slider/lider2.jpg",
    title: "Güçlü Yedek Parçalar, Güvenilir Hizmet",
    // description: "Yüksek performanslı kompozit malzemeler ile geleceğe yönelik çözümler sunuyoruz."
  },
  {
    id: 3,
    image: "/images/anasayfa/slider/lider3.jpg",
    title: "Kalite, Zaman ve Maliyette Üstün Müşteri Memnuniyeti",
    // description: "Doğa ile uyumlu, çevre dostu ürünlerimizle sürdürülebilir bir gelecek inşa ediyoruz."
  }
];

const HomeSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  const handleIndicatorClick = (index) => {
    swiperRef.current?.swiper.slideTo(index);
  };

  const swiperRef = React.useRef(null);

  return (
    <div className="h-[calc(80vh-6rem)] w-full relative flex flex-col">
      <Swiper
        ref={swiperRef}
        onSlideChange={handleSlideChange}
        modules={[Autoplay, EffectFade]}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        effect="fade"
        className="h-full w-full"
      >
        {DATA.map((slide, index) => (
          <SwiperSlide key={slide.id} className="relative">
            <motion.img
              key={activeIndex + "-image"}  // Unique key for image
              className="w-full h-full object-cover"
              src={slide.image}
              alt={slide.title}
              initial={{ opacity: 0.5 }}
              animate={{ opacity: 1 }}
   
              transition={{ duration: 3, ease: "easeOut" }}
            />
            <div className="h-full w-full flex flex-col justify-end items-end top-0 left-0 z-20 absolute py-[5%] px-[8%]">
              <div className="flex flex-col md:max-w-96">
                <motion.h5
                  key={activeIndex + "-title"}  // Unique key for title
                  className="text-end text-white font-bold text-47 mb-5"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
           
                  transition={{ duration: 0.5, ease: "easeOut" }}
                >
                  {slide.title}
                </motion.h5>
                <motion.p
                  key={activeIndex + "-description"}  // Unique key for description
                  className="text-end text-white text-16"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
             
                  transition={{ duration: 0.5, ease: "easeOut", delay: 0.2 }}
                >
                  {slide.description}
                </motion.p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="w-full py-3 flex justify-center items-center space-x-2">
        {DATA.map((_, index) => (
          <div
            key={index}
            className={`h-3 w-3 ${activeIndex === index ? "bg-white border-liderBlue border" : "bg-liderBlue"} cursor-pointer`}
            onClick={() => handleIndicatorClick(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default HomeSlider;
