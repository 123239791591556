import { useEffect } from "react";
import { ReactLenis, useLenis } from '@studio-freight/react-lenis'
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import PageContainer from "./components/PageContainer";
import Anasayfa from "./pages/Anasayfa";
import Kurumsal from "./pages/Kurumsal";
import Urunler from "./pages/Urunler";
import References from "./pages/References";
import Iletisim from "./pages/Iletisim";
import Hizmetlerimiz from "./pages/Hizmetlerimiz";
import Blog from "./pages/Blog";




function App() {
  const location = useLocation();

  return (
    <ReactLenis root>
      <div className="App relative">
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<PageContainer />} >
              <Route path="/" element={<Anasayfa />} />
              <Route path="/kurumsal" element={<Kurumsal />} />
              <Route path="/hizmetlerimiz" element={<Hizmetlerimiz />} />
              <Route path="/urunler" element={<Urunler />} >
                <Route path=":id" >
                  <Route path=":id" />
                </Route>
              </Route>
              <Route path="/referanslar" element={<References />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/iletisim" element={<Iletisim />} />
            </Route>
          </Routes>
        </AnimatePresence>
      </div>
    </ReactLenis>
  );
}

export default App;
