import React from 'react'
import { Link } from 'react-router-dom'

 const menuData =
    [
      {
        title1: 'Ekskavatör',
        title2: 'Ürünleri',
        link: '',
        img: '/images/anasayfa/icons/4.svg',
        productUrl:'ekskavator-urunleri',
        subData: [
          {
            title: 'Hızlı Bağlantı Aparatı(Q-Coupler)',
            link: 'hizli-baglanti-aparati'
          },
          {
            title: 'Genel Maksat Kovası',
            link: 'genel-maksat-kovasi'
          },
          {
            title: 'Ağır Hizmet Kovası ',
            link: 'agir-hizmet-kovasi'
          },
          {
            title: 'Kanal Kovası',
            link: 'kanal-kovasi'
          },
          {
            title: 'Maden Riperleri ',
            link: 'maden-riperleri'
          },
          // {
          //   title: 'Tırnaklı Riperler ',
          //   link: 'tirnakli-riperler'
          // },
          {
            title: 'Kırıcı braket',
            link: 'kirici-braketi'
          },

          {
            title: 'PAMTER ',
            link: 'pamter'
          },
          {
            title: 'RİPER ',
            link: 'riper-pamter'
          },
          {
            title: 'Ana bom & uç bom ve çekirge kolları',
            link: 'ana-bom-uc-bom-ve-cekirgekollari'
          },
        ]
      },







      {
        title1: 'Yükleyici',
        title2: 'Ürünleri',
        link: '',
        img: '/images/anasayfa/icons/5.svg',
        productUrl:'yukleyici-urunleri',

        subData: [
          {
            title: 'Hızlı Bağlantı Aparatı',
            link: 'hizli-baglanti-aparati'
          },
          {
            title: 'Çatal (Fork)',
            link: 'çatal'
          },
          {
            title: 'Tek Diş',
            link: 'tek-dis'
          },
          {
            title: 'PAMTER',
            link: 'pamter'
          },
          {
            title: 'RİPER',
            link: 'riper-pamter'
          },
          {
            title: 'Genel Maksat Kovası',
            link: 'genel-maksat-kovasi'
          },
          {
            title: 'Ağır Hizmet Kovası',
            link: 'agir-hizmet-kovasi'
          },
          {
            title: 'Kanal Kovası',
            link: 'kanal-kovasi'
          },
          {
            title: 'Ana bom & uç bom ve çekirge kolları',
            link: 'ana-bom-uc-bom-ve-cekirgekollari'
          },

        ]
      },







      {
        title1: 'Beko Kazıcı',
        title2: 'Yükleyici',
        link: '',
        img: '/images/anasayfa/icons/6.svg',
        productUrl:'beko-kazici-yukleyici',
        subData: [
          {
            title: 'Hızlı Bağlantı Aparatı(Q-Coupler)',
            link: 'hizli-baglanti-aparati'
          },
          {
            title: 'Genel Maksat Kovası',
            link: 'genel-maksat-kovasi'
          },
          {
            title: 'Ağır Hizmet Kovası',
            link: 'agir-hizmet-kovasi'
          },
          {
            title: 'Kanal Kovası',
            link: 'kanal-kovasi'
          },
          {
            title: 'Maden Riperleri',
            link: 'maden-riperleri'
          },
          // {
          //   title: 'Tırnaklı Riperler',
          //   link: 'tirnakli-riperler'
          // },
          {
            title: 'Kırıcı Braketi',
            link: 'kirici-braketi'
          },
          {
            title: 'Forklift Çatalı',
            link: 'forklift-catali'
          },
        ]
      },
      {
        title1: 'Özel',
        title2: 'Ürünler',
        link: '',
        img: '/images/anasayfa/icons/7.svg',
        productUrl:'ozel-urunler',
        subData: [

          // {
          //   title: 'Karasel Sürgülü Vana',
          //   link: 'karasel-surgulu-vana'
          // },
          {
            title: 'Kürüme Aparatları',
            link: 'kurume-aparatlari'
          },
          {
            title: 'Forklift',
            link: 'forklift'
          },
          {
            title: 'Tomruk Ataşmanları',
            link: 'tomruk-atasmanlari'
          },
          {
            title: 'Piston Grubu',
            link: 'piston-grubu'
          },
  //         {
  //           title: 'Yedek Parça',
  //  link: 'tomruk-atasmanlari'
  //         },
  //         {
  //           title: 'Özel Üretimler',
  //  link: 'tomruk-atasmanlari'
  //         },
        ]
      },
    ]

const Section2 = () => {
  return (
    <section className="w-full  grid grid-cols-10 mb-52">
    <div className="md:col-start-3 md:col-span-6   col-start-2 col-span-8  ">
      <div className="w-full  bg-customGray2 py-10 mb-10 flex flex-col items-center px-[8%]">
        <h5 className="responsive-text-55-4xl font-bold text-center text-liderBlue mb-5">
        Üretim , Tamirat , Yedek Parça ve Ekipman Satışında Lider
        </h5>
        <p className="text-center text-18 text-customGray  w-full md:max-w-[60%]">
        Yedek parça üretiminde müşteri memnuniyetini ön planda tutarak, her süreçte en yüksek kalite standartlarını titizlikle uyguluyoruz. İleri teknolojimiz ve uzman kadromuzla, iş makinelerinizin performansını artıran dayanıklı parçalar sunuyoruz. Gücünüzün arkasındaki güvenilir partneriniz olarak, her zaman yanınızdayız.
        </p>
      </div>

      <div className="  w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">

        {
          menuData.map((item, index) => {
            return (
              <div className=" bg-customGray4 hover:bg-customGray2 group hover:shadow-xl transition-all duration-500 cursor-pointer">
                <div className=" w-full">
                  <img
                    src={item.img}
                    className=" w-full  aspect-square"
                  />

                  <div className="px-8 flex flex-col y-8">
                    <div className="h-px w-full bg-customGray1 "></div>
                    <h6 className="  text-22 font-bold text-liderBlue text-center py-8">
                      {item.title1}
                      <br />
                      {item.title2}
                    </h6>

                  </div>
                </div>

                <div className=" w-full  flex flex-col overflow-hidden h-0 transition-all group-hover:h-72 duration-500 px-8 font-medium text-16 text-liderBlue space-y-1">
                  {
                    item.subData.map((subitem, index) => {
                      return (
                        <div className='flex items-center space-x-1'>
                          <div className='h-1 w-1  rounded-full bg-black'></div>
                        <Link to={`/urunler/${item.productUrl}/${subitem.link}`}><h4 className="">{subitem.title}</h4></Link>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        }




      </div>


    </div>
  </section>




  )
}

export default Section2