export const urunlerData = [
    {
        name: 'Ekskavatör Ürünleri',
        link: 'ekskavator-urunleri',
        data: [
            {
                name: 'Hızlı Bağlantı Aparatı(Q-Coupler)',
                link: 'hizli-baglanti-aparati',
                text: [
                    { p: 'Kısa sürede aparatları sökme takma işlemini yapmaktadır.' },
                    { p: 'Mevcut tüm ürünlerde kullanılabilir.' },
                    { p: 'Makinenin hidrolik tesisatına kolayca bağlanabilir.' },
                    { p: 'Oluşabilecek tehlikelerin önlenmesi adına emniyet valfı ve pimi kullanılabilir.' },
                    { p: 'Aşınan bölümler Hardox® malzemeden yapılmıştır.' },
                    { p: 'Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },

                ],
                mainImage: '/images/products/ekskavator-urunleri/hizli-baglanti-aparati/baglanti-aparati_01.jpg',
                images: [
                    { image: '/images/products/ekskavator-urunleri/hizli-baglanti-aparati/baglanti-aparati_01.jpg' },
                    { image: '/images/products/ekskavator-urunleri/hizli-baglanti-aparati/baglanti-aparati_02.jpg' },
                    { image: '/images/products/ekskavator-urunleri/hizli-baglanti-aparati/baglanti-aparati_03.jpg' },
                    { image: '/images/products/ekskavator-urunleri/hizli-baglanti-aparati/baglanti-aparati_04.jpg' },
                    { image: '/images/products/ekskavator-urunleri/hizli-baglanti-aparati/baglanti-aparati_05.jpg' },
                ]
            },
            {
                name: 'Genel Maksat Kovası ',
                link: 'genel-maksat-kovasi',
                text: [
                    { p: 'Genel amaçlı işler için imal edilmiştir.' },
                    { p: 'Ağız bıçağı düz ve Hardox® tur.' },
                    { p: 'Kova hacimleri ve Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },

                ],
                mainImage: '/images/products/ekskavator-urunleri/genel-maksat-kovasi/genel-maksat-kovasi.jpg',
                images: [
                    { image: '/images/products/ekskavator-urunleri/genel-maksat-kovasi/genel-maksat-kovasi.jpg' },
                ]
            },
            {
                name: 'Ağır Hizmet Kovası ',
                link: 'agir-hizmet-kovasi',
                text: [
                    { p: 'Zorlu çalışma şartları için imal edilmiştir.' },
                    { p: 'Ağız bıçağı, yan bıçaklar, taban aşınma plakaları Hardox® dan imal edilir.' },
                    { p: 'Müşterinin isteğine ve kullanacağı yere göre aşınma plakalarının kullanım alanı artırılır.' },
                    { p: 'Kova hacimleri ve Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },
                ],
                mainImage: '/images/products/ekskavator-urunleri/agir-hizmet-kovasi/agir-hizmet-kovasi_01.jpg',
                images: [
                    { image: '/images/products/ekskavator-urunleri/agir-hizmet-kovasi/agir-hizmet-kovasi_01.jpg' },
                    { image: '/images/products/ekskavator-urunleri/agir-hizmet-kovasi/agir-hizmet-kovasi_02.jpg' },
                    { image: '/images/products/ekskavator-urunleri/agir-hizmet-kovasi/agir-hizmet-kovasi_03.jpg' },
                    { image: '/images/products/ekskavator-urunleri/agir-hizmet-kovasi/agir-hizmet-kovasi_04.jpg' },
                    { image: '/images/products/ekskavator-urunleri/agir-hizmet-kovasi/agir-hizmet-kovasi_05.jpg' },
                ]
            },
            {
                name: 'Kanal Kovası',
                link: 'kanal-kovasi',
                text: [
                    { p: 'Kanal açma için tasarlanmıştır.' },
                    { p: 'Ağız bıçağı Hardox®tur.' },
                    { p: 'Kova hacimleri ve pimlerin geçme yerleri ile eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },
                ],
                mainImage: '/images/products/ekskavator-urunleri/kanal-kovasi/kanal_kovasi.jpg',
                images: [
                    { image: '/images/products/ekskavator-urunleri/kanal-kovasi/kanal_kovasi.jpg' },

                ]
            },
            {
                name: 'Maden Riperleri',
                link: 'maden-riperleri',
                text: [
                    { p: 'Maden ocaklarına ve zorlu çalışma şartlarına göre tasarlanmıştır.' },
                    { p: 'Uç kısım aşınmaya dayanıklı çelikten yapılmıştır.' },
                    { p: 'Gövde ise yüksek mukavemetli çeliktir.' },
                    { p: 'Makinenin gücünü optimum şekilde kullanmak için en uygun kavis verilmiştir.' },
                    { p: 'Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },
                ],
                mainImage: '/images/products/ekskavator-urunleri/maden-riperleri/MADEN_RIPERI_01.jpg',
                images: [
                    { image: '/images/products/ekskavator-urunleri/maden-riperleri/MADEN_RIPERI_01.jpg' },
                ]
            },
            // {
            //     name: 'Tırnaklı Riperler ',
            //     link: 'tirnakli-riperler',
            //     text: [
            //         { p: 'İnşaat ve hafriyat sektörü için üretilmiştir.' },
            //         { p: 'Ağır şartlar için uygun değildir.' },
            //         { p: 'Uç kısmında hazır tırnak bulunmaktadır.' },
            //         { p: 'Gövde yüksek mukavemetli çeliktendir.' },
            //         { p: 'Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
            //         { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },

            //     ],
            //     mainImage: '/images/urunler/1.jpg',
            //     images: [
            //         { image: '/images/urunler/2.png' },
            //         { image: '/images/urunler/3.png' },
            //         { image: '/images/urunler/4.png' },
            //         { image: '/images/urunler/3.png' },
            //     ]
            // },
            {
                name: 'Kırıcı Braketi',
                link: 'kirici-braketi',
                text: [
                    { p: 'Tüm kırıcı ve makine tiplerine uygun olarak her türlü braket imalini yapılmaktadır.' },
                    { p: 'Yüksek mukavemetli çelik kullanılmaktadır.' },
                    { p: 'Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },
                ],
                mainImage: '/images/urunler/1.jpg',
                images: [
                    { image: '/images/products/ekskavator-urunleri/kirici-braketi/kirici-braketi_01.jpg' },
                    { image: '/images/products/ekskavator-urunleri/kirici-braketi/kirici-braketi_02.jpg' },
                    { image: '/images/products/ekskavator-urunleri/kirici-braketi/kirici-braketi_03.jpg' },
                ]
            },





            {
                name: 'PAMTER ',
                link: 'pamter',
                text: [
                    { p: 'Hidrolik gücünü ekskavatörden almaktadır.' },
                    { p: 'Çeşitli yöntemlerle kesilmiş mermer bloklarında oluşturulan yarığa yerleştirilerek ekskavatörden sağlanan hidrolik basınç sayesinde aparatın gagalarına hareket verir ve mermer bloğu devirir.' },
                    { p: '360° mekanik dönen tablası sayesinde mermer yarığına uygun açıda yerleştirilmektedir.' },
                    { p: 'Hardox® malzeme ve yüksek mukavemetli çelik ile imalat yapılmaktadır.Yüksek verimlilik ile çalışmaktadır.' },
                ],
                mainImage: '/images/products/ekskavator-urunleri/pamter/pamter_01.jpg',
                images: [
                    { image: '/images/products/ekskavator-urunleri/pamter/pamter_01.jpg' },
                    { image: '/images/products/ekskavator-urunleri/pamter/pamter_02.jpg' },
                    { image: '/images/products/ekskavator-urunleri/pamter/pamter_03.jpg' },
                    { image: '/images/products/ekskavator-urunleri/pamter/pamter_04.jpg' },
                    { image: '/images/products/ekskavator-urunleri/pamter/pamter_05.jpg' },
                    { image: '/images/products/ekskavator-urunleri/pamter/pamter_06.jpg' },
                    { image: '/images/products/ekskavator-urunleri/pamter/pamter_07.jpg' },
                ]
            },


            {
                name: 'RİPER ',
                link: 'riper',
                text: [
                    { p: 'Hidrolik gücünü ekskavatörden almaktadır.' },
                    { p: 'Çeşitli yöntemlerle kesilmiş mermer bloklarında oluşturulan yarığa yerleştirilerek ekskavatörden sağlanan hidrolik basınç sayesinde mermer bloğu devirmeye yarar.' },
                    { p: 'Sabit tablalı olduğu için sökücü Riper olarak da kullanılabilmektedir.Hardox® malzeme ve yüksek mukavemetli çelik ile imalat yapılmaktadır.' },
                    { p: '3D olarak tasarlanmakta ve kuvvet analizi yapılmaktadır.Yüksek verimlilik ile çalışmaktadır.' },
                    { p: 'Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },
                    { p: 'Hidrolik basınç makine cinsine göre 350-420 bar arasında çalışmaktadır.' },
                    { p: 'Aparat ucu açılma mesafesi 1000 mm dir' },
                ],
                mainImage: '/images/products/ekskavator-urunleri/riper-pamter/riper_01.jpg',
                images: [
                    { image: '/images/products/ekskavator-urunleri/riper-pamter/riper_01.jpg' },
                    { image: '/images/products/ekskavator-urunleri/riper-pamter/riper_02.jpg' },
                    { image: '/images/products/ekskavator-urunleri/riper-pamter/riper_03.jpg' },
                    { image: '/images/products/ekskavator-urunleri/riper-pamter/riper_04.jpg' },
                    { image: '/images/products/ekskavator-urunleri/riper-pamter/riper_05.jpg' },
                ]
            },




            {
                name: 'Ana bom & uç bom ve çekirge kolları  ',
                link: 'ana-bom-uc-bom-ve-cekirgekollari',
                text: [
                    { p: 'Her türlü paletli ve lastikli ekskavatörün ana boom, çekirge, çekirge kolu ve uç boom imalatı yapılır.' },
                    { p: 'Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Yüksek mukavemetli çelik, Hardox® ve Weldox® kullanılmaktadır.' },
                    { p: 'Yataklarda SnBz-12 kullanılmaktadır.' },
                ],
                mainImage: '/images/urunler/1.jpg',
                images: [
                    { image: '/images/products/ekskavator-urunleri/ana-bom-uc-bom-ve-cekirgekollari/anaboom_01.jpg' },
                    { image: '/images/products/ekskavator-urunleri/ana-bom-uc-bom-ve-cekirgekollari/anaboom_02.jpg' },
                    { image: '/images/products/ekskavator-urunleri/ana-bom-uc-bom-ve-cekirgekollari/anaboom_03.jpg' },
                    { image: '/images/products/ekskavator-urunleri/ana-bom-uc-bom-ve-cekirgekollari/anaboom_04.jpg' },
                ]
            },

        ]

    },
    {
    
        name: 'Yükleyici Ürünleri',
        link: 'yukleyici-urunleri',
        data: [
            {
                name: 'Hızlı Bağlantı Aparatı(Q-Coupler)',
                link: 'hizli-baglanti-aparati',
                text: [
                    { p: 'Kısa sürede aparatları sökme takma işlemini yapmaktadır.' },
                    { p: 'Mevcut tüm ürünlerde kullanılabilir.' },
                    { p: 'Makinenin hidrolik tesisatına kolayca bağlanabilir.' },
                    { p: 'Oluşabilecek tehlikelerin önlenmesi adına emniyet valfı ve pimi kullanılabilir.' },
                    { p: 'Aşınan bölümler Hardox® malzemeden yapılmıştır.' },
                    { p: 'Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },

                ],
                mainImage: '/images/products/yukleyici-urunleri/hizli-baglanti-aparati/baglanti-aparati_01.jpg',
                images: [
                    { image: '/images/products/yukleyici-urunleri/hizli-baglanti-aparati/baglanti-aparati_01.jpg' },
                    { image: '/images/products/yukleyici-urunleri/hizli-baglanti-aparati/baglanti-aparati_02.jpg' },
                    { image: '/images/products/yukleyici-urunleri/hizli-baglanti-aparati/baglanti-aparati_03.jpg' },
                    { image: '/images/products/yukleyici-urunleri/hizli-baglanti-aparati/baglanti-aparati_04.jpg' },
                    { image: '/images/products/yukleyici-urunleri/hizli-baglanti-aparati/baglanti-aparati_05.jpg' },

                ]
            },

            {
                name: 'Çatal (Fork)',
                link: 'catal',
                text: [
                    { p: 'Fork (Çatal) Ataşmanı, özel çalışma şartlarına göre tasarlanıp üretilir ve büyük taş veya mermer bloklarının taşınması ve yüklenmesi için ideal bir çözüm sunar.' },
                    { p: 'Müşterinin isteğine ve çalışma şartlarına bağlı olarak Hardox® Weldox® ve yüksek mukavemetli çeliklerden imal edilir.' },
                    { p: 'Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },
                ],
                mainImage: '/images/urunler/1.jpg',
                images: [
                    { image: '/images/products/yukleyici-urunleri/catal/catal_01.jpg' },
                    { image: '/images/products/yukleyici-urunleri/catal/catal_02.jpg' },
                    { image: '/images/products/yukleyici-urunleri/catal/catal_03.jpg' },
                    { image: '/images/products/yukleyici-urunleri/catal/catal_04.jpg' },
                ]
            },

            {
                name: 'Tek Diş',
                link: 'tek-dis',
                text: [
                    { p: 'Müşterinin isteğine ve Çalışma Şartlarına bağlı olarak Hardox® Weldox® ve Yüksek mukavemetli çeliklerden imal edilir.' },
                    { p: 'Operatörün görüşünü engellemeyecek şekilde tasarlanmıştır.' },
                    { p: 'Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },
                ],
                mainImage: '/images/products/yukleyici-urunleri/tek-dis/tekdis_01.jpg',
                images: [
                    { image: '/images/products/yukleyici-urunleri/tek-dis/tekdis_01.jpg' },
                    { image: '/images/products/yukleyici-urunleri/tek-dis/tekdis_02.jpg' },
                ]
            },
            {
                name: 'PAMTER ',
                link: 'pamter',
                text: [
                    { p: 'Hidrolik gücünü ekskavatörden almaktadır.' },
                    { p: 'Çeşitli yöntemlerle kesilmiş mermer bloklarında oluşturulan yarığa yerleştirilerek ekskavatörden sağlanan hidrolik basınç sayesinde aparatın gagalarına hareket verir ve mermer bloğu devirir.' },
                    { p: '360° mekanik dönen tablası sayesinde mermer yarığına uygun açıda yerleştirilmektedir.' },
                    { p: 'Hardox® malzeme ve yüksek mukavemetli çelik ile imalat yapılmaktadır.Yüksek verimlilik ile çalışmaktadır.' },
                ],
                mainImage: '/images/products/yukleyici-urunleri/pamter/pamter_01.jpg' ,
                images: [
                    { image: '/images/products/yukleyici-urunleri/pamter/pamter_01.jpg' },
                    { image: '/images/products/yukleyici-urunleri/pamter/pamter_02.jpg' },
                    { image: '/images/products/yukleyici-urunleri/pamter/pamter_03.jpg' },
                    { image: '/images/products/yukleyici-urunleri/pamter/pamter_04.jpg' },
                    { image: '/images/products/yukleyici-urunleri/pamter/pamter_05.jpg' },
                    { image: '/images/products/yukleyici-urunleri/pamter/pamter_06.jpg' },
                    { image: '/images/products/yukleyici-urunleri/pamter/pamter_07.jpg' },
                ]
            },


            {
                name: 'RİPER',
                link: 'riper',
                text: [
                    { p: 'Hidrolik gücünü ekskavatörden almaktadır.' },
                    { p: 'Çeşitli yöntemlerle kesilmiş mermer bloklarında oluşturulan yarığa yerleştirilerek ekskavatörden sağlanan hidrolik basınç sayesinde mermer bloğu devirmeye yarar.' },
                    { p: 'Sabit tablalı olduğu için sökücü Riper olarak da kullanılabilmektedir.Hardox® malzeme ve yüksek mukavemetli çelik ile imalat yapılmaktadır.' },
                    { p: '3D olarak tasarlanmakta ve kuvvet analizi yapılmaktadır.Yüksek verimlilik ile çalışmaktadır.' },
                    { p: 'Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },
                    { p: 'Hidrolik basınç makine cinsine göre 350-420 bar arasında çalışmaktadır.' },
                    { p: 'Aparat ucu açılma mesafesi 1000 mm dir' },
                ],
                mainImage: '/images/products/yukleyici-urunleri/riper-pamter/riper_01.jpg',
                images: [
                    { image: '/images/products/yukleyici-urunleri/riper-pamter/riper_01.jpg' },
                    { image: '/images/products/yukleyici-urunleri/riper-pamter/riper_02.jpg' },
                    { image: '/images/products/yukleyici-urunleri/riper-pamter/riper_03.jpg' },
                    { image: '/images/products/yukleyici-urunleri/riper-pamter/riper_04.jpg' },
                    { image: '/images/products/yukleyici-urunleri/riper-pamter/riper_05.jpg' },
                ]
            },
            {
                name: 'Genel Maksat Kovası ',
                link: 'genel-maksat-kovasi',
                text: [
                    { p: 'Genel amaçlı işler için imal edilmiştir.' },
                    { p: 'Ağız bıçağı düz ve Hardox® tur.' },
                    { p: 'Kova hacimleri ve Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },

                ],
                mainImage: '/images/products/yukleyici-urunleri/genel-maksat-kovasi/genel-maksat-kovasi.jpg',
                images: [
                    { image: '/images/products/yukleyici-urunleri/genel-maksat-kovasi/genel-maksat-kovasi.jpg' },
                ]
            },
            {
                name: 'Ağır Hizmet Kovası ',
                link: 'agir-hizmet-kovasi',
                text: [
                    { p: 'Zorlu çalışma şartları için imal edilmiştir.' },
                    { p: 'Ağız bıçağı, yan bıçaklar, taban aşınma plakaları Hardox® dan imal edilir.' },
                    { p: 'Müşterinin isteğine ve kullanacağı yere göre aşınma plakalarının kullanım alanı artırılır.' },
                    { p: 'Kova hacimleri ve Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },
                ],
                mainImage: '/images/products/yukleyici-urunleri/agir-hizmet-kovasi/agir-hizmet-kovasi_01.jpg',
                images: [
                    { image: '/images/products/yukleyici-urunleri/agir-hizmet-kovasi/agir-hizmet-kovasi_01.jpg' },
                    { image: '/images/products/yukleyici-urunleri/agir-hizmet-kovasi/agir-hizmet-kovasi_02.jpg' },
                    { image: '/images/products/yukleyici-urunleri/agir-hizmet-kovasi/agir-hizmet-kovasi_03.jpg' },
                    { image: '/images/products/yukleyici-urunleri/agir-hizmet-kovasi/agir-hizmet-kovasi_04.jpg' },
                    { image: '/images/products/yukleyici-urunleri/agir-hizmet-kovasi/agir-hizmet-kovasi_05.jpg' },
                ]
            },
            {
                name: 'Kanal Kovası',
                link: 'kanal-kovasi',
                text: [
                    { p: 'Kanal açma için tasarlanmıştır.' },
                    { p: 'Ağız bıçağı Hardox®tur.' },
                    { p: 'Kova hacimleri ve pimlerin geçme yerleri ile eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },
                ],
                mainImage: '/images/products/yukleyici-urunleri/kanal-kovasi/kanal_kovasi.jpg',
                images: [
                    { image: '/images/products/yukleyici-urunleri/kanal-kovasi/kanal_kovasi.jpg' },

                ]
            },
            {
                name: 'Ana bom & uç bom ve çekirge kolları  ',
                link: 'ana-bom-uc-bom-ve-cekirgekollari',
                text: [
                    { p: 'Her türlü paletli ve lastikli ekskavatörün ana boom, çekirge, çekirge kolu ve uç boom imalatı yapılır.' },
                    { p: 'Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Yüksek mukavemetli çelik, Hardox® ve Weldox® kullanılmaktadır.' },
                    { p: 'Yataklarda SnBz-12 kullanılmaktadır.' },
                ],
                mainImage: '/images/products/yukleyici-urunleri/ana-bom-uc-bom-ve-cekirgekollari/anaboom_01.jpg',
                images: [
                    { image: '/images/products/yukleyici-urunleri/ana-bom-uc-bom-ve-cekirgekollari/anaboom_01.jpg' },
                    { image: '/images/products/yukleyici-urunleri/ana-bom-uc-bom-ve-cekirgekollari/anaboom_02.jpg' },
                    { image: '/images/products/yukleyici-urunleri/ana-bom-uc-bom-ve-cekirgekollari/anaboom_03.jpg' },
                    { image: '/images/products/yukleyici-urunleri/ana-bom-uc-bom-ve-cekirgekollari/anaboom_04.jpg' },
                ]
            },

        ]

    },

    {
    
        name: 'Beko Kazıcı Yükleyici',
        link: 'beko-kazici-yukleyici',
        data: [
            {
                name: 'Hızlı Bağlantı Aparatı(Q-Coupler)',
                link: 'hizli-baglanti-aparati',
                text: [
                    { p: 'Kısa sürede aparatları sökme takma işlemini yapmaktadır.' },
                    { p: 'Mevcut tüm ürünlerde kullanılabilir.' },
                    { p: 'Makinenin hidrolik tesisatına kolayca bağlanabilir.' },
                    { p: 'Oluşabilecek tehlikelerin önlenmesi adına emniyet valfı ve pimi kullanılabilir.' },
                    { p: 'Aşınan bölümler Hardox® malzemeden yapılmıştır.' },
                    { p: 'Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },

                ],
                mainImage: '/images/urunler/1.jpg',
                images: [
                    { image: '/images/products/beko-kazici-yukleyici/hizli-baglanti-aparati/baglanti-aparati_01.jpg' },
                    { image: '/images/products/beko-kazici-yukleyici/hizli-baglanti-aparati/baglanti-aparati_02.jpg' },
                    { image: '/images/products/beko-kazici-yukleyici/hizli-baglanti-aparati/baglanti-aparati_03.jpg' },
                    { image: '/images/products/beko-kazici-yukleyici/hizli-baglanti-aparati/baglanti-aparati_04.jpg' },
                    { image: '/images/products/beko-kazici-yukleyici/hizli-baglanti-aparati/baglanti-aparati_05.jpg' },
                ]
            },
            {
                name: 'Genel Maksat Kovası ',
                link: 'genel-maksat-kovasi',
                text: [
                    { p: 'Genel amaçlı işler için imal edilmiştir.' },
                    { p: 'Ağız bıçağı düz ve Hardox® tur.' },
                    { p: 'Kova hacimleri ve Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },

                ],
                mainImage: '/images/products/beko-kazici-yukleyici/genel-maksat-kovasi/genel-maksat-kovasi.jpg',
                images: [
                    { image: '/images/products/beko-kazici-yukleyici/genel-maksat-kovasi/genel-maksat-kovasi.jpg' },
                ]
            },
            {
                name: 'Ağır Hizmet Kovası ',
                link: 'agir-hizmet-kovasi',
                text: [
                    { p: 'Zorlu çalışma şartları için imal edilmiştir.' },
                    { p: 'Ağız bıçağı, yan bıçaklar, taban aşınma plakaları Hardox® dan imal edilir.' },
                    { p: 'Müşterinin isteğine ve kullanacağı yere göre aşınma plakalarının kullanım alanı artırılır.' },
                    { p: 'Kova hacimleri ve Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },
                ],
                mainImage: '/images/products/beko-kazici-yukleyici/agir-hizmet-kovasi/agir-hizmet-kovasi_01.jpg',
                images: [
                    { image: '/images/products/beko-kazici-yukleyici/agir-hizmet-kovasi/agir-hizmet-kovasi_01.jpg' },
                    { image: '/images/products/beko-kazici-yukleyici/agir-hizmet-kovasi/agir-hizmet-kovasi_02.jpg' },
                    { image: '/images/products/beko-kazici-yukleyici/agir-hizmet-kovasi/agir-hizmet-kovasi_03.jpg' },
                    { image: '/images/products/beko-kazici-yukleyici/agir-hizmet-kovasi/agir-hizmet-kovasi_04.jpg' },
                    { image: '/images/products/beko-kazici-yukleyici/agir-hizmet-kovasi/agir-hizmet-kovasi_05.jpg' },
                ]
            },
            {
                name: 'Kanal Kovası',
                link: 'kanal-kovasi',
                text: [
                    { p: 'Kanal açma için tasarlanmıştır.' },
                    { p: 'Ağız bıçağı Hardox®tur.' },
                    { p: 'Kova hacimleri ve pimlerin geçme yerleri ile eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },
                ],
                mainImage: '/images/products/beko-kazici-yukleyici/kanal-kovasi/kanal_kovasi.jpg',
                images: [
                    { image: '/images/products/beko-kazici-yukleyici/kanal-kovasi/kanal_kovasi.jpg' },

                ]
            },
            {
                name: 'Maden Riperleri',
                link: 'maden-riperleri',
                text: [
                    { p: 'Maden ocaklarına ve zorlu çalışma şartlarına göre tasarlanmıştır.' },
                    { p: 'Uç kısım aşınmaya dayanıklı çelikten yapılmıştır.' },
                    { p: 'Gövde ise yüksek mukavemetli çeliktir.' },
                    { p: 'Makinenin gücünü optimum şekilde kullanmak için en uygun kavis verilmiştir.' },
                    { p: 'Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },
                ],
                mainImage: '/images/products/beko-kazici-yukleyici/maden-riperleri/MADEN_RIPERI_01.jpg',
                images: [
                    { image: '/images/products/beko-kazici-yukleyici/maden-riperleri/MADEN_RIPERI_01.jpg' },

                ]
            },
            // {
            //     name: 'Tırnaklı Riperler ',
            //     link: 'tirnakli-riperler',
            //     text: [
            //         { p: 'İnşaat ve hafriyat sektörü için üretilmiştir.' },
            //         { p: 'Ağır şartlar için uygun değildir.' },
            //         { p: 'Uç kısmında hazır tırnak bulunmaktadır.' },
            //         { p: 'Gövde yüksek mukavemetli çeliktendir.' },
            //         { p: 'Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
            //         { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },

            //     ],
            //     mainImage: '/images/urunler/1.jpg',
            //     images: [
            //         { image: '/images/urunler/2.png' },
            //         { image: '/images/urunler/3.png' },
            //         { image: '/images/urunler/4.png' },
            //         { image: '/images/urunler/3.png' },
            //     ]
            // },
            {
                name: 'Kırıcı Braketi',
                link: 'kirici-braketi',
                text: [
                    { p: 'Tüm kırıcı ve makine tiplerine uygun olarak her türlü braket imalini yapılmaktadır.' },
                    { p: 'Yüksek mukavemetli çelik kullanılmaktadır.' },
                    { p: 'Pimlerin geçme yerleri ve eksen ölçülerinde makine üreticisinin sağladığı orijinal ölçüler kullanılmaktadır.' },
                    { p: 'Hızlı bağlantı aparatı ile sorunsuz şekilde kısa sürede sökülüp takılabilmektedir.' },
                ],
                mainImage: '/images/products/beko-kazici-yukleyici/kirici-braketi/kirici-braketi_01.jpg',
                images: [
                    { image: '/images/products/beko-kazici-yukleyici/kirici-braketi/kirici-braketi_01.jpg' },
                    { image: '/images/products/beko-kazici-yukleyici/kirici-braketi/kirici-braketi_02.jpg' },
                    { image: '/images/products/beko-kazici-yukleyici/kirici-braketi/kirici-braketi_03.jpg' },
                ]
            },
            {
                name: 'Forklift Çatalı',
                link: 'forklift-catali',
                text: [
                    { p: 'Tesis ve liman gibi alanlarda büyük palet ve konteynır gibi yüklerin taşınmasında ve yüklenmesinde kullanılır. Tırnak boyları 1250 ile 2400mm arasındaki ebatlarda müşteri ihtiyacına göre belirlenir. 3-14 ton arasındaki yükler için uygundur.' },
                ],
                mainImage: '/images/products/beko-kazici-yukleyici/forklift-catali/forklift_catali_01.jpg',
                images: [
                    { image: '/images/products/beko-kazici-yukleyici/forklift-catali/forklift_catali_01.jpg' },
                    { image: '/images/products/beko-kazici-yukleyici/forklift-catali/forklift_catali_02.jpg' },
                    { image: '/images/products/beko-kazici-yukleyici/forklift-catali/forklift_catali_03.jpg' },
   
                ]
            },

        ]

    },


    {

        name: 'Özel Ürünler',
        link: 'ozel-urunler',
        data: [
            // {
            //     name: 'Karasel Sürgülü Vana',
            //     link: 'karasel-surgulu-vana',
            //     text: [
            //         { p: 'Endüstriyel süreçler için dayanıklı ve güvenilir akış kontrolü sağlar. Zorlu koşullarda dahi yüksek performans gösterir ve kolay montaj imkânı sunar.' },
            //     ],
            //     mainImage: '/images/urunler/1.jpg',
            //     images: [
            //         { image: '/images/urunler/1.jpg' },
            //         { image: '/images/urunler/1.jpg' },
            //         { image: '/images/urunler/1.jpg' },
            //         { image: '/images/urunler/1.jpg' },
            //     ]
            // },
            {
                name: 'Kürüme Aparatları',
                link: 'kurume-aparatlari',
                text: [
                    { p: 'Üretim süreçlerinde hassas ve güvenilir kürüme sağlar. Kaliteyi artırmak için tasarlanmış bu aparatlar, en yüksek performansı elde etmenize yardımcı olur.' },

                ],
                mainImage: '/images/products/ozel-urunler/kurume-aparatlari/kureme_kovasi.jpg',
                images: [
                    { image: '/images/products/ozel-urunler/kurume-aparatlari/kureme_kovasi.jpg' },

                ]
            },
            {
                name: 'Forklift',
                link: 'forklift',
                text: [
                    { p: 'Yük taşıma ve istifleme işlemlerini güçlendiren, dayanıklı ve ergonomik bir çözüm sunar. Güvenli kullanım ve düşük bakım gereksinimleri ile operasyonlarınızı hızlandırır.' },
                ],
                mainImage: '/images/products/ozel-urunler/forklift/rgr.jpg',
                images: [
                    { image: '/images/products/ozel-urunler/forklift/rgr.jpg' },
                ]
            },
            {
                name: 'Tomruk Ataşmanları',
                link: 'tomruk-atasmanlari',
                text: [
                    { p: 'Tomrukların taşınması ve tırlara yüklenmesinde kullanılmaktadır.' },
                  
                ],
                mainImage: '/images/products/ozel-urunler/tomruk-atasmanlari/tomruk_catali.jpg',
                images: [
                    { image: '/images/products/ozel-urunler/tomruk-atasmanlari/tomruk_catali.jpg' },

                ]
            },
            {
                name: 'Piston Grubu',
                link: 'piston-grubu',
                text: [
                    { p: 'İhtiyaca uygun özelliklerde piston imalatı ve yüksek kaliteli piston tamiratı ile operasyonlarınız kesintisiz bir şekilde devam eder.' },
                  
                ],
                mainImage: '/images/products/ozel-urunler/piston/piston_01.jpg',
                images: [
                    { image: '/images/products/ozel-urunler/piston/piston_01.jpg' },
                    { image: '/images/products/ozel-urunler/piston/piston_02.jpg' },

                ]
            },

        ]

    },
]