import React from 'react'
import UrunlerGaleryComponent from './UrunlerGaleryComponent'
import { motion } from 'framer-motion';

const UrunlerContentComponent = ({ filteredSecondPath }) => {
 console.log(filteredSecondPath)
       

    if (!filteredSecondPath) {
        return (
            <div className='h-72 w-full bg-amber-600'></div>
        )
    }

   

    return (
    
        
        <div className='col-span-5 md:col-span-4 space-y-10'>
    {           filteredSecondPath.map((item,index)=>{
         const isEven = index % 2 === 0;
            return(
                
                <div key={index} className=' grid grid-cols-1 md:grid-cols-2 md:gap-5'>
                <motion.div  className={` text-16 mb-5  ${isEven?'md:order-1':'md:order-2'}`}>
                    <div className='text-32 font-bold text-liderBlue mb-2'>{item.name}</div>
                    <div className='space-y-2'>
                        {
                            item.text.map((item,index)=>{
                                return(
                                    <div>{item.p}</div>
                                )
                            })
                        }
                    </div>

                </motion.div>

                <motion.div   className={` ${isEven?'md:order-2':'md:order-1'}`}>
                    <UrunlerGaleryComponent  images={item.images}/>
                </motion.div>
            </div>
            )
        })}
            
       

        </div>

    )
}

export default UrunlerContentComponent