
import { motion } from 'framer-motion'
import { Link, useLocation, useParams } from 'react-router-dom'
import { DATA } from '../../data/header-data'
const HeaderDeskop = () => {
  
const {pathname} = useLocation()
    console.log(pathname)
   
  return (
<div className=" h-full w-full hidden  lg:flex  items-center ">

<nav className="w-full h-full  flex justify-center items-center  text-14 ">

  <ul className='w-full h-full  flex  justify-between items-center '>
    {
      DATA.map((item, index) => {
        
        return (
          <li key={index} className='group cursor-pointer relative flex h-full  items-center   transition-all duration-500 '>

            <Link to={item.link} className='flex h-full flex-col justify-center relative' >


              <motion.h6 className={`${item.link === pathname ? 'text-ulusOrange' : 'text-black'} flex   items-center font-bold group-hover:!text-ulusOrange !text-14 openSans   transition-all duration-500`}>
                {item.title}
              </motion.h6>
              <div className={ `h-1 w-0 transition-all duration-1000 ${item.link === pathname  ? 'bg-ulusOrange w-6' : 'bg-none w-0'}`}></div>

            </Link>
            {
              item.dropDown &&
              <ul className='absolute opacity-0 top-[6rem] left-0 invisible group-hover:visible  bg-white  group-hover:opacity-100  overflow-hidden   transition-all duration-500 w-max  group-hover:translate-y-0 translate-y-20 shadow-2xl'>
                <div className='relative h-full w-full  '>
                  <div className='absolute h-full w-full group-hover:h-0 bg-turkuazGreen z-40 transition-all duration-500'></div>

                  {
                    item.dropDown.map((item, index) => {
                      return (
                        <li key={index} className='group/innerDropDown '>

                          <Link to={item.link}>



                            <motion.div className={` px-4 py-2 flex  !text-14 openSans font-semibold relative`}>
                              <div className='w-0 h-full bg-turkuazGreen top-0 left-0 absolute group-hover/innerDropDown:w-full transition-all duration-500 '></div>
                              {/* <div className='w-0.5 h-full bg-turkuazGreen absolute left-0 top-0 py-1'></div> */}
                              <h5 className='relative z-20 group-hover/innerDropDown:!text-ulusOrange transition duration-500 text-black'>   {item.title}</h5>



                              {item.dropDown && (<img className='ml-2 transform group-hover/innerDropDown:rotate-90 transition-all duration-500' src='/image/header/miniHook.svg' />)}
                            </motion.div>

                          </Link>

                          {
                          item.dropDown &&
                          <ul className='h-0 group-hover/innerDropDown:h-auto bg-transparent  overflow-hidden transition-all duration-500  '>
                            {
                              item.dropDown.map((item, index) => {
                                return (
                                  <Link to={item.link}>

                                    <li key={index} className='hover:text-tedakblue'>
                                      <motion.h6 className='  pl-5 '> {item.title}</motion.h6>
                                    </li>

                                  </Link>
                                )
                              })
                            }
                          </ul>
                        }

                        </li>
                      )
                    })
                  }
                </div>
              </ul>
            }
          </li>
        )
      })
    }
  </ul>
</nav>
</div>
  )
}

export default HeaderDeskop