import React, { useState, useEffect } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';

import { Link } from 'react-router-dom';
import HeaderDeskop from './HeaderDeskop';


const Header = ({ toggle, setToggle }) => {
  const { scrollY } = useScroll();
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isScrollingUp, setIsScrollingUp] = useState(true);

  useEffect(() => {
    return scrollY.onChange((latest) => {
      if (latest > lastScrollY) {
        setIsScrollingUp(false);
      } else {
        setIsScrollingUp(true);
      }
      setLastScrollY(latest);
    });
  }, [scrollY, lastScrollY]);

  const headerVariants = {
    hidden: { y: -100 },
    visible: { y: 0 },
  };

  return (
    <motion.div
      className="h-24 w-full grid grid-cols-10 sticky top-0 z-50 bg-white "
      initial="visible"
      animate={isScrollingUp ? 'visible' : 'hidden'}
      variants={headerVariants}
      transition={{ duration: 0.3 }}
    >
      
      <div className="col-start-2 col-span-8 h-full w-full grid grid-cols-4 items-center ">
        <Link to={'/'} className="col-span-2 lg:col-span-1 flex items-center h-full">
          <img src="/images/logo/lider-logo.svg" className="h-16" />
        </Link>
        <div className="col-span-1 lg:col-span-2 h-full">
          <div className="w-full h-full">
            <HeaderDeskop  />
          </div>
        </div>

        <div className='  col-span-1 h-full hidden lg:flex justify-end items-center space-x-2'>
            <div className='h-6 w-6  flex justify-center items-center text-14 text-customGray3 border-customGray3 border'>TR</div>
            <div className='h-6 w-6  flex justify-center items-center text-14 text-black'>EN</div>

        </div>
        <div
          className="w-full flex justify-end lg:hidden cursor-pointer"
          onClick={() => setToggle(!toggle)}
        >
          <div className="h-10 w-10 flex flex-col justify-center overflow-hidden relative">
            <div
              className={`h-0.5 w-10 absolute left-0 bg-[#707070] transition-all duration-500 ${
                toggle ? 'rotate-45' : 'rotate-0'
              } ${toggle ? 'top-3.5' : 'top-0'}`}
            ></div>
            <div
              className={`h-0.5 w-6 absolute top-3.5 left-0 bg-[#707070] transition-all duration-500 ${
                toggle ? '-translate-x-full' : 'translate-x-0'
              }`}
            ></div>
            <div
              className={`h-0.5 w-10 absolute left-0 bg-[#707070] transition-all duration-500 ${
                toggle ? '-rotate-45' : 'rotate-0'
              } ${toggle ? 'top-3.5' : 'top-7'}`}
            ></div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Header;
